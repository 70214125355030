import React from "react";
import FooterSection from "../../../sections/Footer";
import Top from "../../../sections/Top";
import PageTitle from "../../../components/PageTitle";
import SubHeading from "../../../sections/SubHeading";
import RightImage from "./RightImage";
import SimpleText from "./SimpleText";
import LeftImage from "./LeftImage";
import SimpleTextBottom from "./SimpleTextBottom";
import ProjectsPagination from "../../../sections/ProjectsPagination";
import ProjectsTestimonial from "../../../sections/ProjectsTestimonial";
import Details from "./Details";

const RamarooUgc = () => {
  return (
    <>
      <PageTitle title="Ramaroo UGC Video Player | @designwithella.com" />
      <Top
        title="Ramaroo UGC Video Player"
        titleBgOne="#7143DB"
        titleBgTwo="#2ED3F7"
        titleBgThree="#508CE8"
      />
      <SubHeading text="Video shopping platform designed for brands to uplift conversions and increase retention" />
      <RightImage />
      <SimpleText />
      <LeftImage />
      <SimpleTextBottom />
      <Details />
      <ProjectsTestimonial
        reviewOne="I hired Ella as our very first UI/UX designer and have loved working with her ever since. She is a very promising digital product designer, learns quickly and is also very easy to work with. "
        reviewTwo="Combine that with a great work ethic and an overall pleasant personality, I am happy to conclude that hiring Ella was one of my best hiring decisions."
        name="Reginald Ofori"
        job="Founder Shopceed (Ramaroo)"
      />
      <ProjectsPagination
        prevTitle={"STRATandGO Software application"}
        prevcolor={"#4D8219"}
        prevUrl={"/project-strat-and-go"}
        nextTitle={"Bovoba SaaS Application"}
        nextcolor={"#3C83F6"}
        nextUrl={"/project-bovoba-dashboard"}
      />
      <FooterSection />
    </>
  );
};

export default RamarooUgc;
