import React from "react";
import styled from "styled-components";

import stratandgo2 from "../../../images/portfolio/stratandgo2.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Right = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
    img {
      margin-bottom: 60px;
    }
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const LeftImage = () => {
  return (
    <StylesSection>
      <Left>
        <img src={stratandgo2} alt="strat-and-go" />
      </Left>
      <Right>
        <div className="group text-format">
          <h3>The solution</h3>
          <ul>
            <li>
              Good user experience: I ensured that when users engaged with the
              interface, they had straightforward controls, an intuitive user
              flow, and an overall smooth experience.
            </li>
            <li>
              Employ simple language: Avoid technical jargon and complex
              phrases, and instead use simple language that users can
              understand.
            </li>
            <li>
              I strategically used design elements like buttons, icons, and
              labels to guide users through their tasks and next steps.
            </li>
            <li>
              Keep the interface clean and uncluttered by concentrating on the
              most critical information and tasks. This will prevent consumers
              from becoming overwhelmed or confused.
            </li>
            <li>
              Provide feedback: Let users know what's going on as they engage
              with the interface, such as confirming that an action has been
              accomplished or warning them if they're about to do something
              irreversible, giving them a sense of control and confidence in
              their actions.
            </li>
          </ul>
        </div>
      </Right>
    </StylesSection>
  );
};

export default LeftImage;
