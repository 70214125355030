import React from "react";
import styled from "styled-components";

const StyledH2 = styled.h2`
  padding: 60px 20px 0px 20px;
  text-align: center;
  font-weight: 400;
`;

const SubHeading = ({ text }) => {
  return <StyledH2>{text}</StyledH2>;
};

export default SubHeading;
