import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 60px auto;
  text-align: left;
  padding: 0 20px;
`;

const SimpleText = () => {
  return (
    <Text className="text-format">
      <h3>My approach</h3>
      <p>
        My recent UX design project relied heavily on communication and
        collaboration. Working together with the business manager enabled me to
        create effective design solutions and detect hidden dangers. It also
        enabled me to obtain useful feedback and insights, ensuring that the
        final work reached the appropriate criteria.
      </p>
      <p>
        I used a design framework to create a set of standards and reusable
        components to maintain uniformity across displays and interactions.
      </p>
    </Text>
  );
};

export default SimpleText;
