import React from "react";

import FooterSection from "../../../sections/Footer";
import Top from "../../../sections/Top";
import PageTitle from "../../../components/PageTitle";
import SubHeading from "../../../sections/SubHeading";
import RightImage from "./RightImage";
import SimpleText from "./SimpleText";
import LeftImage from "./LeftImage";
import SimpleTextBottom from "./SimpleTextBottom";

import ProjectsPagination from "../../../sections/ProjectsPagination";
import ProjectsTestimonial from "../../../sections/ProjectsTestimonial";
import Details from "./Details";

const Bovobadashboard = () => {
  return (
    <>
      <PageTitle title="Bovoba SaaS Application | @designwithella.com" />
      <Top
        title="Bovoba SaaS Application"
        titleBgOne="#3C83F6"
        titleBgTwo="#7EC5D7"
        titleBgThree="#5493F0"
      />
      <SubHeading text="E-Commerce integration to notify customers when fulfilment and shipping delays occur" />
      <RightImage />
      <SimpleText />
      <LeftImage />
      <SimpleTextBottom />
      <Details />
      <ProjectsTestimonial
        reviewOne="I hired Ella as our very first UI/UX designer and have loved working with her ever since. She is a very promising digital product designer, learns quickly and is also very easy to work with. "
        reviewTwo="Combine that with a great work ethic and an overall pleasant personality, I am happy to conclude that hiring Ella was one of my best hiring decisions."
        name="Reginald Ofori"
        job="Founder Shopceed (Bovoba)"
      />
      <ProjectsPagination
        prevTitle={"Ramaroo UGC Video Player"}
        prevUrl={"/project-ramaroo-ugc-video-player"}
        prevcolor={"#508CE8"}
        nextTitle={"Brightening Hero E-Commerce"}
        nextUrl={"/project-brightening-hero"}
        nextcolor={"#5B9346"}
      />
      <FooterSection />
    </>
  );
};

export default Bovobadashboard;
