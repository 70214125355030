import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  max-width: var(--content-size);
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 150px auto;
  padding: 0px;
  @media (max-width: 1150px) {
    margin: 100px auto;
  }
  @media (max-width: 950px) {
    flex-wrap: wrap;
  }
`;

const LeftSide = styled.div`
  height: 100%;
  width: 48%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 950px) {
    width: 100%;
    height: auto;
    padding-right: 20px;
  }
`;

const RightSide = styled.div`
  height: 100%;
  width: 48%;
  display: flex;
  align-items: center;
  @media (max-width: 950px) {
    height: auto;
    width: 80%;
    margin: 0 auto;
  }
`;

const StyledText = styled.div`
  p {
    font-size: 16px;
    color: var(--dark);
    text-align: left;
    margin: 0 0 30px 0;
    line-height: 1.4;
    font-weight: 400;
    span {
      font-weight: 600;
    }
    @media (max-width: 950px) {
      margin: 0 0 15px 0;
    }
  }
`;
const currentYear = new Date().getFullYear();
const yearsAgo = currentYear - 2018;

const AboutHome = () => {
  return (
    <section className="section section--about">
      <Content className="content">
        <LeftSide className="left">
          <StyledText>
            <p>
              <span>Ella</span> is a curious UX/UI Freelance Designer who is
              focused mainly on software applications and bespoke websites.
              Also, she is passionate about emerging technologies, social good
              and advocating UX design across all fields of work.
            </p>
            <p>
              {yearsAgo} years ago she made a conscious decision to move into
              design; she started to learn about graphic design and her interest
              grew for UX/UI and the whole design process.
            </p>

            <p>
              After some time, she decided to deepen her UX understanding, so
              she took a User Experience Nanodegree from Udacity.
            </p>
            <p>
              It was the best decision for her to enter this field, now she does
              something that cannot be called work but a passion.
            </p>
            <p>
              She enjoys working on projects that require both strategy as well
              as visual design from the ideation stage and seeing them through
              completion through pixel-perfect designs.
            </p>
            <p>
              In her free time, <span>Ella</span> loves to cook, bake, hike and
              travel. Overall, she hopes to inspire and influence through the
              combination of creativity, strategy and culture.
            </p>
          </StyledText>
        </LeftSide>
        <RightSide className="right">
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_dv3etasb.json"
            background="transparent"
            speed="1"
            loop
            autoplay
            style={{ width: "100%", margin: "0 auto" }}
          ></lottie-player>
        </RightSide>
      </Content>
    </section>
  );
};

export default AboutHome;
