import React from "react";
import { Helmet } from "react-helmet";

const PageTitle = ({
  title = "Hi | UX Freelance Designer - @designwithella.com",
}) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default PageTitle;
