import React from "react";

import FooterSection from "../../../sections/Footer";
import Top from "../../../sections/Top";
import PageTitle from "../../../components/PageTitle";
import SubHeading from "../../../sections/SubHeading";
import ProjectsTestimonial from "../../../sections/ProjectsTestimonial";
import RightImage from "./RightImage";
import SimpleText from "./SimpleText";
import LeftImage from "./LeftImage";

import ProjectsPagination from "../../../sections/ProjectsPagination";
import Details from "./Details";

const DarkSpectrum = () => {
  return (
    <>
      <PageTitle title="Dark Spectrum tattoo | @designwithella.com" />
      <Top
        title="Dark Spectrum Tattoo"
        titleBgOne="#D63B6F"
        titleBgTwo="#8A678F"
        titleBgThree="#ED636B"
      />
      <SubHeading text="Tattoo Shop website design concept" />
      <RightImage />
      <LeftImage />
      <SimpleText />
      <Details />
      <ProjectsTestimonial
        reviewOne="First and foremost, we want to mention that working with Ella was a highly fruitful, challenging, and lovely experience that produced a first-rate service and a fantastic final product for our business."
        reviewTwo="She is aware of how to make a task as simple as feasible for the user. Ella's attention to detail and general UI creativity resulted in a wonderful website with an outstanding user experience. She has our highest recommendation, and we will work together again on other projects."
        name="Dragos Mincu & Sebastian Ursache"
        job="Founders Dark Spectrum Tattoo"
      />
      <ProjectsPagination
        prevTitle={"Brightening Hero E-Commerce"}
        prevUrl={"/project-brightening-hero"}
        prevcolor={"#5B9346"}
        nextTitle={"First Run Fitness Mobile App"}
        nextUrl={"/project-fitness-app"}
        nextcolor={"#ED9526"}
      />
      <FooterSection />
    </>
  );
};

export default DarkSpectrum;
