import React from "react";
import styled from "styled-components";

import brighteninghero1 from "../../../images/portfolio/brightening-hero-first.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 60px auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Left = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const RightImage = () => {
  return (
    <StylesSection>
      <Left>
        <div className="group text-format">
          <h3>Background</h3>
          <p>
            As a UX designer, I was tasked with creating a user-centric website
            for Brightening Hero, a skincare product that targets skin
            discoloration.
          </p>
          <p>
            The goal was to provide an intuitive and engaging platform where
            users could learn about the product, understand its benefits, and
            make purchases.
          </p>
        </div>
        <div className="group text-format">
          <h3>The challenge</h3>
          <p>
            The challenge was to design a website that would effectively
            communicate the complex information about the product's unique
            formula and its benefits.
          </p>
          <p>
            The website needed to cater to a diverse audience with varying skin
            tones and skincare needs.
          </p>
          <p>
            It was also important to highlight the product's ethical attributes,
            such as being vegan, cruelty-free, and recyclable.
          </p>
        </div>
      </Left>
      <Right>
        <img src={brighteninghero1} alt="brightening-hero-uk" />
      </Right>
    </StylesSection>
  );
};

export default RightImage;
