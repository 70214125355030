import React from "react";
import styled from "styled-components";

import stratandgo1 from "../../../images/portfolio/stratandgo1.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 60px auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Left = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const RightImage = () => {
  return (
    <StylesSection>
      <Left>
        <div className="group text-format">
          <h3>Background</h3>
          <p>
            A good strategy is only good if it is implemented. Experience shows
            that companies very often have good strategies, but do not pursue
            and implement them.
          </p>
          <p>
            STRATandGO™ is a corporate performance management system that offers
            a multi-stage concept for consistently implementing and monitoring
            strategic goals.
          </p>
          <p>
            The software helps bring together all important company information
            for a relevant all-around view with great modules and features.
          </p>
        </div>
        <div className="group text-format">
          <h3>Project description</h3>
          <p>
            STRATandGO’s requirement was to redesign their desktop and web
            application.
          </p>
          <p>
            My objective was to create a modern, user-friendly software
            application that would allow STRATandGO to exhibit its
            long-established brand while also providing a seamless user
            experience.
          </p>
        </div>
      </Left>
      <Right>
        <img src={stratandgo1} alt="strat-and-go" />
      </Right>
    </StylesSection>
  );
};

export default RightImage;
