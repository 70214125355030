import React from "react";
import styled from "styled-components";

import firstrun2 from "../../../images/portfolio/firstrun2.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Right = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
  .topMargin {
    margin-top: 80px;
    @media (max-width: 1150px) {
      margin-top: 0;
    }
  }
`;
const Left = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
    img {
      margin-bottom: 60px;
    }
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const LeftImage = () => {
  return (
    <StylesSection>
      <Left>
        <img src={firstrun2} alt="fitness" />
      </Left>
      <Right>
        <div className="group text-format topMargin">
          <h3>The solution</h3>
          <p>
            The solution was a fitness app that offered a structured 9-week
            running plan, with three runs per week and rest days in between.
          </p>
          <p>
            The app featured a choice of audio coaches to provide support and
            motivation, guiding users on when to run, walk, and stop.
          </p>
          <p>
            It also allowed users to customise their running plan, play music or
            audiobooks, and receive alerts at the halfway point of their run.
          </p>
          <p>
            In terms of design, the app was created with a user-friendly
            interface, featuring noticeable buttons that were harmoniously
            integrated into the overall stylistic concept, yet contrasted enough
            to stand out in the layout.
          </p>
          <p>
            A bright colour scheme was used to enhance the visual hierarchy on
            the page, drawing users' attention to the key interactive zones.
          </p>
          <p>
            This thoughtful design approach ensured that users could easily
            navigate the app and focus on their running plan without being
            overwhelmed by unnecessary features.
          </p>
        </div>
      </Right>
    </StylesSection>
  );
};

export default LeftImage;
