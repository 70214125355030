import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 0 auto 60px auto;
  text-align: left;
  padding: 0 20px;
  .topMargin {
    margin-top: 60px;
  }
  ul {
    @media (max-width: 1150px) {
      margin-top: -40px;
    }
  }
`;

const SimpleTextBottom = () => {
  return (
    <Text className="text-format">
      <ul>
        <li>
          Visual Content Representation: Video previews or thumbnails were
          displayed prominently in a visually appealing manner. Key video
          details, such as title, description, and duration, were presented
          clearly and consistently giving the user the possibility to
          expand/minimise or close the widget.
        </li>
        <li>
          Visual Cues for Interaction: Interactive elements, such as buttons and
          links, were visually highlighted to provide clear indications of
          clickability. Hover and selection states were designed to give users
          immediate visual feedback, enhancing the overall interactivity and
          usability of the app.
        </li>
        <li>
          Providing Feedback: I wanted to create an interface that communicates
          with the users as they engage with it, such as confirming that an
          action has been accomplished or warning them if they're about to do
          something irreversible, giving them a sense of control and confidence
          in their actions.
        </li>
      </ul>
      <div className="group text-format topMargin">
        <h3>Used tools</h3>
        <p>
          The project was executed using a suite of tools including Figma for
          design, Miro for brainstorming, Loom for video communication, ClickUP
          for project management, and Notion for note-taking and organisation.
        </p>
      </div>
      <div className="group text-format">
        <h3>Personal conclusion</h3>
        <p>
          The Ramaroo UGC Video Player project highlighted the impact of
          user-centric design on user experience and business growth.
        </p>
        <p>
          The design and implementation process was challenging yet rewarding,
          reinforcing the importance of collaboration, iterative design, and
          user feedback. Also, the positive user feedback and increased
          engagement, content discovery, and sales validated the invested
          effort.
        </p>
        <p>
          This project has fueled my commitment to create intuitive, engaging
          user experiences that drive business results, and I look forward to
          applying these insights to future projects.
        </p>
      </div>
    </Text>
  );
};

export default SimpleTextBottom;
