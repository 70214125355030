import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 0 auto 60px auto;
  text-align: left;
  padding: 0 20px;
  .topMargin {
    margin-top: 60px;
  }
  ul {
    @media (max-width: 1150px) {
      margin-top: -40px;
    }
  }
`;

const SimpleTextBottom = () => {
  return (
    <Text className="text-format">
      <p>
        The footer menu was designed to provide self-serve options, allowing
        users to find information on their own and reducing the need for
        customer service interactions.
      </p>
      <p>
        User testing was conducted at various stages of the design process to
        validate design decisions and iterate based on real user feedback.
      </p>
      <p>
        This ensured that the final design was not only visually appealing but
        also functional and user-friendly.
      </p>
      <p>
        The result was a clean, intuitive, and engaging website that effectively
        communicated the benefits of Brightening Hero, provided a seamless
        shopping experience, and led to a high conversion rate.
      </p>
      <div className="group text-format topMargin">
        <h3>Collaboration</h3>
        <p>
          A key aspect of this project was the collaboration with the
          development team. I started by creating a site map of the website,
          outlining the architecture and flow of the pages.This provided a clear
          roadmap for both the design and development teams.
        </p>
        <p>
          To streamline the design-to-development handoff, I established a
          design component library. The components library served as a single
          source of truth for both teams, containing all the design elements
          used in the project.
        </p>
        <p>
          Throughout the implementation process, I worked closely with the
          developers, providing them with detailed design specifications and
          collaborating on any design-related issues that arose during the
          development phase. This close collaboration ensured that the final
          product accurately reflected the intended design and provided a
          seamless user experience.
        </p>
      </div>
      <div className="group text-format">
        <h3>The result</h3>
        <p>
          The Brightening Hero website successfully provided a user-friendly
          platform for customers to learn about the product and make purchases.
          The design effectively communicated the product's unique formula and
          its benefits, leading to a positive user experience. The website's
          clear, intuitive navigation and layout allowed users to easily find
          the information they were looking for, contributing to increased user
          engagement and satisfaction.
        </p>
      </div>
      <div className="group text-format">
        <h3>Used tools</h3>
        <p>
          Figma for UI design, Miro for brainstorming and planning, Loom for
          communication and collaboration, ClickUP for project management,
          Notion for documentation.
        </p>
      </div>
    </Text>
  );
};

export default SimpleTextBottom;
