import React from "react";
import FooterSection from "../../../sections/Footer";
import Top from "../../../sections/Top";
import PageTitle from "../../../components/PageTitle";
import SubHeading from "../../../sections/SubHeading";
import RightImage from "./RightImage";
import SimpleText from "./SimpleText";
import LeftImage from "./LeftImage";
import SimpleTextBottom from "./SimpleTextBottom";
import ProjectsPagination from "../../../sections/ProjectsPagination";

const FitnessApp = () => {
  return (
    <div className="fitness-app">
      <PageTitle title="Fitness Mobile Application | @designwithella.com" />
      <Top
        title="First Run"
        titleBgOne="#ED9526"
        titleBgTwo="#F2B05C"
        titleBgThree="#D4B011"
      />
      <SubHeading text="Mobile Fitness App for new runners" />
      <RightImage />
      <SimpleText />
      <LeftImage />
      <SimpleTextBottom />
      <ProjectsPagination
        prevTitle={"Dark Spectrum Tattoo Website"}
        prevUrl={"/project-dark-spectrum-tattoo"}
        prevcolor={"#D63B6F"}
        nextTitle={"STRATandGO Software application"}
        nextUrl={"/project-strat-and-go"}
        nextcolor={"#4D8219"}
      />
      <FooterSection />
    </div>
  );
};

export default FitnessApp;
