import React from "react";
import styled from "styled-components";

import firstrun1 from "../../../images/portfolio/firstrun1.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 0px auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Left = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const RightImage = () => {
  return (
    <StylesSection>
      <Left>
        <div className="group text-format">
          <h3>Background</h3>
          <p>
            The project was centred around the development of a fitness app
            specifically tailored to the needs of first-time runners.
          </p>
          <p>
            The goal was to create an intuitive and user-friendly platform that
            would encourage and guide new runners to gradually increase their
            running endurance over time.
          </p>
        </div>
        <div className="group text-format">
          <h3>The challenge</h3>
          <p>
            The primary challenge was to design an app that catered to the
            unique needs of first-time runners.
          </p>
          <p>
            My research indicated that most fitness apps on the market were
            designed for experienced runners and were often overwhelming for
            beginners due to their complexity and multitude of features.
          </p>
          <p>
            The biggest hurdle for new runners was not knowing how or where to
            start, and they expressed a desire for a structured plan and
            motivational support.
          </p>
        </div>
      </Left>
      <Right>
        <img src={firstrun1} alt="fitness-app" />
      </Right>
    </StylesSection>
  );
};

export default RightImage;
