import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Pagination = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 60px auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  .link {
    text-decoration: none;
    position: relative;
    transition: 0.2s ease-in;
    width: fit-content;
    &:after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      bottom: -3px;
      left: 0;
      background: var(--dark-green);
      transition: 0.2s ease-in;
    }
    &:hover {
      cursor: pointer;
      color: var(--dark-green);
      transition: 0.2s ease-in;
      &:after {
        width: 100%;
      }
    }
    p {
      color: var(--dark);
      margin-bottom: 5px;
    }
  }
  .prev {
    max-width: 45%;
    text-align: left;
    color: ${(props) => `${props.prevcolor}`};
    @media (max-width: 600px) {
      max-width: 48%;
    }
    span {
      margin-right: 6px;
    }
    p {
      margin-left: 20px;
      @media (max-width: 950px) {
        margin-left: unset;
      }
    }
    &:hover {
      @media (max-width: 950px) {
        color: ${(props) => `${props.prevcolor}`};
        &:after {
          width: 0%;
        }
      }
    }
  }

  .next {
    max-width: 45%;
    text-align: right;
    color: ${(props) => `${props.nextcolor}`};
    @media (max-width: 600px) {
      max-width: 48%;
    }
    span {
      margin-left: 6px;
    }
    p {
      margin-right: 20px;
      @media (max-width: 950px) {
        margin-right: unset;
      }
    }
    &:hover {
      @media (max-width: 950px) {
        color: ${(props) => `${props.nextcolor}`};
        &:after {
          width: 0%;
        }
      }
    }
  }
`;

const ProjectsPagination = ({
  prevTitle,
  nextTitle,
  prevUrl,
  nextUrl,
  prevcolor = "var(--dark)",
  nextcolor = "var(--dark)",
}) => {
  return (
    <Pagination prevcolor={prevcolor} nextcolor={nextcolor}>
      <NavLink to={prevUrl} className="link prev">
        <p>Previous project</p>
        <span>{"<  "}</span>
        {prevTitle}
      </NavLink>
      <NavLink to={nextUrl} className="link next">
        <p>Next project</p>
        {nextTitle}
        <span>{"  >"}</span>
      </NavLink>
    </Pagination>
  );
};

export default ProjectsPagination;
