import React from "react";
import FooterSection from "../../../sections/Footer";
import Top from "../../../sections/Top";
import PageTitle from "../../../components/PageTitle";
import RightImage from "./RightImage";
import SubHeading from "../../../sections/SubHeading";
import SimpleText from "./SimpleText";
import LeftImage from "./LeftImage";
import ProjectsPagination from "../../../sections/ProjectsPagination";
import ProjectsTestimonial from "../../../sections/ProjectsTestimonial";
import SimpleTextBottom from "./SimpleTextBottom";
import Details from "./Details";

const StratAndGo = () => {
  return (
    <>
      <PageTitle title="STRATandGO Software App | @designwithella.com" />
      <Top
        title={`STRATandGO DESKTOP SOFTWARE APPLICATION`}
        uppercase={false}
        titleBgOne="#99B84A"
        titleBgTwo="#4D8219"
        titleBgThree="#A0D960"
      />
      <SubHeading text="Strategy and Corporate Performance Management System" />
      <RightImage />
      <SimpleText />
      <LeftImage />
      <SimpleTextBottom />
      <Details />
      <ProjectsTestimonial
        reviewOne="I highly recommend Ella if you need to get the job done! We hired her to improve our Design and User Experience for our management software."
        reviewTwo="She listened closely to all our requirements and I was impressed by her attention to detail."
        name="Nedal Abdouni"
        job="COO Procos AG"
      />
      <ProjectsPagination
        prevTitle={"First Run Fitness Mobile App"}
        prevUrl={"/project-fitness-app"}
        prevcolor={"#ED9526"}
        nextTitle={"Ramaroo UGC Video Player"}
        nextUrl={"/project-ramaroo-ugc-video-player"}
        nextcolor={"#508CE8"}
      />
      <FooterSection />
    </>
  );
};

export default StratAndGo;
