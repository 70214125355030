import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import FooterSection from "../sections/Footer";
import Top from "../sections/Top";

import PageTitle from "../components/PageTitle";

const StyledNotFound = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-betwwen;
  .top {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    h1,
    h2 {
      font-weight: 500;
    }
    h2 {
      margin-top: 40px;
    }
  }
  .return {
    text-decoration: none;
    padding: 8px 14px;
    transition: 0.2s ease-in;
    background: var(--dark-green);
    color: var(--light);
    font-size: 20px;
    margin-top: 10px;
    &:hover {
      content: "";
      background: var(--c-one);
      color: var(--dark);
      @media (max-width: 600px) {
        background: var(--dark-green);
        color: var(--light);
      }
    }
  }
`;

const NotFound = () => {
  return (
    <StyledNotFound>
      <PageTitle title="Page not found | @designwithella.com" />
      <Top title="Whoops! This page decided to play hide and seek" />
      <div className="top">
        <h2>But hey, don't let that stop you.</h2>
        <NavLink to="/" className="return">
          Return home
        </NavLink>
      </div>
      <FooterSection />
    </StyledNotFound>
  );
};

export default NotFound;
