import React from "react";
import styled from "styled-components";

import ramaroo2 from "../../../images/portfolio/ramaroo2.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Right = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
    img {
      margin-bottom: 60px;
    }
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const LeftImage = () => {
  return (
    <StylesSection>
      <Left>
        <img src={ramaroo2} alt="ramaroo" />
      </Left>
      <Right>
        <div className="group text-format">
          <h3>The solution</h3>
          <p>
            The goal was to achieve an attractive, intuitive and user-friendly
            interface that enhances the video discovery, organisation and
            personalization experience.
          </p>
          <ul>
            <li>
              Streamlined Onboarding: I designed a user-friendly onboarding
              process that guides new users through the app's features and
              functionality.
            </li>
            <li>
              Simple and Intuitive Navigation: The navigation menu was designed
              to be easily accessible and provides straightforward access to key
              sections, such as dashboard, product feed, media bank, search and
              filters.
            </li>
            <li>
              User-Centric Search Experience: The search functionality was
              designed with the user in mind. I added intelligent
              auto-suggestions and filters as date added, product name, video
              status, creator type, ensuring quick and accurate search results.
              The search results page presents relevant videos in a visually
              appealing and informative manner, helping users find the desired
              content efficiently.
            </li>
            <li>
              Intuitive Content Organization: I offered intuitive ways for users
              to organise their video collections. Users can create personalised
              collections or folders to categorise and manage their favourite
              videos efficiently.
            </li>
          </ul>
        </div>
      </Right>
    </StylesSection>
  );
};

export default LeftImage;
