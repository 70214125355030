import React from "react";
import styled from "styled-components";

import bovoba1 from "../../../images/portfolio/bovoba1.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 60px auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Left = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
  }
  img {
    width: 100%;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
    max-width: 750px;
  }
`;

const RightImage = () => {
  return (
    <StylesSection>
      <Left>
        <div className="group text-format">
          <h3>Background</h3>
          <p>
            Bovoba is a SaaS application that assists online stores in
            proactively notifying customers about fulfilment and shipping
            delays. My role in this project was to redesign the dashboard to
            improve usability and efficiency.
          </p>
          <p>
            My role in this project was to redesign the dashboard to improve
            usability and efficiency.
          </p>
        </div>
        <div className="group text-format">
          <h3>The challenge</h3>
          <p>
            The existing dashboard needed a more intuitive design to help users
            quickly understand and manage potential delivery and fulfilment
            issues.
          </p>
          <p>
            The challenge was to present complex data in a clear,
            easy-to-understand format, and to design a system for tracking and
            managing potential issues.
          </p>
        </div>
      </Left>
      <Right>
        <img src={bovoba1} alt="bovoba-app" />
      </Right>
    </StylesSection>
  );
};

export default RightImage;
