import React from "react";
import styled from "styled-components";

const Testimonial = styled.section`
  width: 50%;
  max-width: var(--content-size);
  margin: 60px auto;
  padding: 20px;
  @media (max-width: 1700px) {
    width: 70%;
  }
  @media (max-width: 1150px) {
    width: 90%;
  }
  h3 {
    font-weight: 400;
    position: relative;
    line-height: 30px;
    font-size: 20px;
    @media (max-width: 1150px) {
      font-size: 18px;
    }
    .revOne {
      position: relative;
      text-align: left;
      margin-bottom: 20px;
      &:before {
        content: "''";
        font-size: 40px;
        font-style: italic;
        opacity: 0.3;
        position: absolute;
        left: -25px;
        top: 0;
      }
    }
    .revTwo {
      position: relative;
      text-align: left;
      &:before {
        content: "''";
        font-size: 40px;
        font-style: italic;
        opacity: 0.3;
        position: absolute;
        right: -15px;
        bottom: -20px;
      }
    }
    .author {
      text-align: right;
      margin-top: 20px;
      span {
        font-weight: 600;
      }
    }
  }
`;

const ProjectsTestimonial = ({ reviewOne, reviewTwo, name, job }) => {
  return (
    <Testimonial>
      <h3>
        <p className="revOne">{reviewOne}</p>
        <p className="revTwo">{reviewTwo}</p>
        <p className="author">
          <span>{name}</span>, {job}
        </p>
      </h3>
    </Testimonial>
  );
};

export default ProjectsTestimonial;
