import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 60px auto;
  text-align: left;
  padding: 0 20px;
`;

const SimpleText = () => {
  return (
    <Text className="text-format">
      <div className="group text-format">
        <h3>My approach</h3>
        <p>
          I began by understanding the key functionalities of Bovoba, including
          its two main features: the Delivery Checker and the Fulfilment
          Checker. These features track shipments and fulfilment times,
          respectively, and notify the relevant parties if there are any delays.
        </p>
        <p>
          Second step was to analyse the types of flagged statuses and follow-up
          actions that could occur, and how these were currently represented in
          the dashboard.
        </p>
        <p>
          I also studied the additional explainer text provided for each flagged
          status, and how this information could be presented more effectively.
        </p>
      </div>
    </Text>
  );
};

export default SimpleText;
