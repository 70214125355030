import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 0 auto 60px auto;
  text-align: left;
  padding: 0 20px;
  .topMargin {
    margin-top: 60px;
    @media (max-width: 1250px) {
      margin-top: unset;
    }
  }
  ul {
    @media (max-width: 1150px) {
      margin-top: -40px;
    }
  }
`;

const SimpleTextBottom = () => {
  return (
    <Text className="text-format">
      <div className="group text-format topMargin">
        <h3>Used tools</h3>
        <p>
          Figma for UI design, Miro for brainstorming and planning, Loom for
          communication and collaboration.
        </p>
      </div>
    </Text>
  );
};

export default SimpleTextBottom;
