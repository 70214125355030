import React from "react";
import styled from "styled-components";

const StyledTop = styled.section`
  background: ${(props) => `${props.background}`};
  min-height: 30vh;
  margin-top: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.h1`
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  padding: 20px;
  font-weight: 500;
  text-align: center;
  text-transform: ${(props) => (props.data.uppercase ? "uppercase" : "unset")};
  font-size: 40px;
  user-select: none;
  line-height: 50px;
  letter-spacing: 15px;
  background: ${(props) => `linear-gradient(
    45deg,
    ${props.data.titleBgOne} 5%,
    ${props.data.titleBgTwo} 47.4%,
    ${props.data.titleBgThree} 98.43%
  )`};
  background-size: 300% 300%;
  animation: gradient-animation 6s ease infinite;
  background-blend-mode: lighten;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  max-width: 70%;
  margin: 0 auto;
  @media (max-width: 1150px) {
    max-width: 90%;
    font-size: 34px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
    max-width: 100%;
    letter-spacing: 10px;
  }
`;

const Top = ({
  title,
  titleBgOne = "var(--c-two)",
  titleBgTwo = " var(--dark-green)",
  titleBgThree = "#5f9ea0",
  background = "var(--dark)",
  uppercase = true,
}) => {
  const titleProps = { titleBgOne, titleBgTwo, titleBgThree, uppercase };

  return (
    <StyledTop background={background}>
      <StyledTitle data={titleProps}>{title}</StyledTitle>
    </StyledTop>
  );
};

export default Top;
