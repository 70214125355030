import AboutHome from "../sections/AboutHome";
import IntroHome from "../sections/IntroHome";
import Footer from "../sections/Footer";

import styled from "styled-components";
import ProjectsHome from "../sections/ProjectsHome";
import PageTitle from "../components/PageTitle";

const HomePage = styled.div`
  .section--home {
    background-color: var(--dark);
    flex-direction: unset;
    align-items: unset;
    z-index: 2;
  }

  .section--about {
    z-index: 1;
    background-color: transparent;
  }
`;
const Home = () => {
  return (
    <HomePage className="home-page">
      <PageTitle />
      <IntroHome />
      <AboutHome />
      <ProjectsHome />
      <Footer />
    </HomePage>
  );
};

export default Home;
