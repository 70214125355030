import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Strat from "../images/logos/logo-stratandgo.svg";
import Bovoba from "../images/logos/logo-bovoba.svg";
import Ramaroo from "../images/logos/logo-ramaroo.svg";
import Brightening from "../images/logos/logo-brighteninghero.svg";

const StyledProjects = styled.section`
  margin: 80px 0 100px 0;
  h3 {
    color: var(--dark-green);
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 500;
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px 40px;
    width: 100%;
    max-width: var(--content-size);
    margin: 0 auto 30px auto;
    padding: 20px;
    @media (max-width: 1150px) {
      grid-template-columns: 1fr;
    }
    .card {
      list-style-type: none;
      min-height: 300px;
      background: var(--dark);
      color: var(--light);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 40px 20px;
      text-decoration: none;
      transition: 0.2s ease-in;
      position: relative;
      @media (max-width: 600px) {
        min-height: 200px;
        flex-direction: column;
        padding-top: 0;
      }
      &:after {
        content: "View project";
        position: absolute;
        right: 10px;
        bottom: -10px;
        opacity: 0;
        transition: 0.2s ease-in;
        color: var(--c-two);
        @media (max-width: 600px) {
          opacity: 1;
          bottom: 10px;
        }
      }
      &:hover {
        opacity: 0.9;
        &:after {
          opacity: 1;
          bottom: 10px;
        }
        @media (max-width: 600px) {
          opacity: unset;
        }
      }
      .logo {
        @media (max-width: 600px) {
          width: 100%;
        }
        img {
          max-width: 150px;
          max-height: 150px;
          user-drag: none;
          user-select: none;
          -webkit-user-drag: none;
        }
      }
      .text {
        width: 67%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        @media (max-width: 600px) {
          width: 100%;
        }
        .title {
          font-size: 26px;
          margin-bottom: 14px;
          color: var(--c-two);
          text-align: left;
          font-weight: 300;
        }
        .subtitle {
          font-size: 20px;
          text-align: left;
          font-weight: 300;
        }
      }
    }
  }

  .more-projects {
    text-decoration: none;
    padding: 8px 14px;
    transition: 0.2s ease-in;
    background: var(--dark-green);
    color: var(--light);
    font-size: 20px;
    &:hover {
      content: "";
      background: var(--c-one);
      color: var(--dark);
      @media (max-width: 600px) {
        background: var(--dark-green);
        color: var(--light);
      }
    }
  }
`;

const ProjectsHome = () => {
  return (
    <StyledProjects>
      <h3>Latest projects</h3>
      <div className="cards">
        <NavLink to="/project-strat-and-go" className="card card--1">
          <div className="logo">
            <img
              src={Strat}
              alt="project-strat-and-go"
              width="150"
              height="150"
            />
          </div>

          <div className="text">
            <p className="title">STRATandGO Desktop Software Application</p>
            <p className="subtitle">
              Strategy and Corporate Performance Management System
            </p>
          </div>
        </NavLink>
        <NavLink
          to="/project-ramaroo-ugc-video-player"
          className="card card--2"
        >
          <div className="logo">
            <img
              src={Ramaroo}
              alt="project-ramaroo-ugc-video-player"
              width="150"
              height="150"
            />
          </div>
          <div className="text">
            <p className="title">Ramaroo UGC Video Player</p>
            <p className="subtitle">
              Video shopping platform designed for brands to uplift conversions
              and increase retention
            </p>
          </div>
        </NavLink>
        <NavLink to="/project-bovoba-dashboard" className="card card--3">
          <div className="logo">
            <img
              src={Bovoba}
              alt="project-bovoba-dashboard"
              width="150"
              height="150"
            />
          </div>
          <div className="text">
            <p className="title">Bovoba SaaS Application</p>
            <p className="subtitle">
              E&#8209;Commerce integration to notify customers when fulfilment
              and shipping delays occur
            </p>
          </div>
        </NavLink>
        <NavLink to="/project-brightening-hero" className="card card--4">
          <div className="logo">
            <img
              src={Brightening}
              alt="project-brightening-hero"
              width="150"
              height="150"
            />
          </div>
          <div className="text">
            <p className="title">Brightening Hero E&#8209;Commerce</p>
            <p className="subtitle">
              Bespoke e&#8209;commerce responsive website
            </p>
          </div>
        </NavLink>
      </div>
      <NavLink to="/projects" className="more-projects">
        See more projects
      </NavLink>
    </StyledProjects>
  );
};

export default ProjectsHome;
