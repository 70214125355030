import { createGlobalStyle } from "styled-components";

const Global = createGlobalStyle`
 
  @font-face {
    font-family: 'Montserrat';
    src: asset-url('Montserrat-SemiBold.woff2') format('woff2'),
    asset-url('Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: asset-url('Montserrat-Regular.woff2') format('woff2'),
    asset-url('Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: asset-url('Montserrat-Medium.woff2') format('woff2'),
    asset-url('Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

  html {
    font-family: 'Montserrat', sans-serif;
  }

  .text-format {
    margin-bottom: 60px;
    h3 {
      font-weight: 400;
      margin-bottom: 15px;
      color: var(--dark-green);
      font-size: 20px;
    }
    p, li {
      font-size: 16px;
      color: var(--dark);
      text-align: left;
      margin: 0 0 15px 0;
      line-height: 1.4;
      font-weight: 400;
    }
    li {
      margin-left: 20px;
    }
  }

`;

export default Global;
