import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 60px auto;
  text-align: left;
  padding: 0 20px;
`;

const SimpleText = () => {
  return (
    <Text className="text-format">
      <div className="group text-format">
        <h3>My approach</h3>
        <p>
          My approach to this project was rooted in providing custom and bespoke
          solutions tailored to the unique needs of Brightening Hero and its
          diverse user base.
        </p>
        <p>
          I started with comprehensive market research to understand the
          competitive landscape, the target audience's skincare concerns, their
          online shopping habits, and their preferences when it comes to
          skincare products.
        </p>
        <p>
          I aimed to design solutions without bias and assumptions, ensuring
          that the design decisions were data-driven and user-focused.
        </p>
        <p>
          To achieve this, I conducted user research and usability testing at
          various stages of the design process. This allowed me to validate
          design decisions and iterate based on real user feedback.
        </p>
        <p>
          Quality and consistency standards were at the forefront of my design
          process. I established a design system, including a component library,
          to ensure consistency across the website. This not only streamlined
          the design-to-development handoff but also ensured a consistent user
          experience across different parts of the website.
        </p>
        <p>
          To keep stakeholders involved and informed, I implemented a
          transparent project management process that allowed them to track the
          project's progress. Regular updates and reviews were conducted to
          ensure alignment with the project goals and to incorporate feedback in
          a timely manner.
        </p>
        <p>
          This approach ensured that the final design was not only user-centric
          and intuitive but also met the high-quality standards and unique needs
          of Brightening Hero.
        </p>
      </div>
    </Text>
  );
};

export default SimpleText;
