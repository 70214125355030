import React from "react";
import styled from "styled-components";

import brighteninghero2 from "../../../images/portfolio/brightening-hero-second.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Right = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
    img {
      margin-bottom: 60px;
    }
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const LeftImage = () => {
  return (
    <StylesSection>
      <Left>
        <img src={brighteninghero2} alt="brightening-hero" />
      </Left>
      <Right>
        <div className="group text-format">
          <h3>The solution</h3>
          <p>
            The final design was a user-centric website that effectively
            communicated the benefits of Brightening Hero and provided a
            seamless shopping experience. The home page, the start of a
            customer's journey, was designed to be engaging and inviting.
          </p>
          <p>
            The navigation was made clear and intuitive, guiding users through
            the website with ease.
          </p>
          <p>
            One of the key features of the design was the clear guidance on
            adding items to the cart. This, coupled with an easy payment system,
            was aimed at providing a frictionless shopping experience, leading
            to a high conversion rate.
          </p>
          <p>
            Call to action buttons were strategically placed and made highly
            visible to guide users towards making a purchase.
          </p>
          <p>
            The website also featured a sticky header with a call to action,
            ensuring that the option to purchase was always visible, regardless
            of where the user was on the page.
          </p>
          <p>
            The navigation menu was designed to be expandable, providing users
            with easy access to submenus and further information as needed.
          </p>
        </div>
      </Right>
    </StylesSection>
  );
};

export default LeftImage;
