import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 60px auto;
  text-align: left;
  padding: 0 20px;
`;

const SimpleText = () => {
  return (
    <Text className="text-format">
      <div className="group text-format">
        <h3>My approach</h3>
        <p>The approach was rooted in user-centric design principles.</p>
        <p>
          I conducted semi-structured interviews to understand the experiences
          of first-time runners and their interactions with existing fitness
          apps.
        </p>
        <p>
          The goal was to identify opportunities for creating a fitness app that
          would resonate with new runners and provide them with the tools they
          needed to succeed.
        </p>
      </div>
    </Text>
  );
};

export default SimpleText;
