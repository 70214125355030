import React from "react";
import styled from "styled-components";

import ramaroo1 from "../../../images/portfolio/ramaroo1.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 60px auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Left = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
  .challenge-left {
    @media (max-width: 1400px) {
      display: none;
    }
  }
`;
const Right = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const RightImage = () => {
  return (
    <StylesSection>
      <Left>
        <div className="group text-format">
          <h3>Background</h3>
          <p>
            The Ramaroo UGC Video Player allows e-commerce businesses to embed
            and showcase user-generated content (UGC) directly within their
            online stores.
          </p>
          <p>
            The use of UGC on an e-commerce website can increase social proof
            and authenticity, which can help to strengthen customer trust.
          </p>
          <p>
            Additionally, UGC can provide valuable insights into how customers
            are using and interacting with a product, which can help businesses
            improve their offerings.
          </p>
          <p>
            A video player can be particularly effective for showcasing UGC, as
            it allows customers to see the product in action and get a better
            sense of its features and benefits. It also provides a more
            immersive and engaging experience than static images or text-based
            reviews.
          </p>
        </div>
        <div className="group text-format challenge-left">
          <h3>The challenge</h3>
          <p>
            Ramaroo UGC Video Player app aims to address the challenges online
            store users face when discovering and organising video content
            across multiple platforms.
          </p>
          <p>
            Users often struggle to find relevant videos that match their
            interests, navigate through vast content libraries efficiently, and
            manage their personal video collections effectively. This leads to
            frustration, wasted time, and missed opportunities to increase
            sales.
          </p>
        </div>
      </Left>
      <Right>
        <img src={ramaroo1} alt="ramaroo-ugc" />
      </Right>
    </StylesSection>
  );
};

export default RightImage;
