import React from "react";
import styled from "styled-components";

import darkspectrum2 from "../../../images/portfolio/darkspectrum2.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Right = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
    order: 2;
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const LeftImage = () => {
  return (
    <StylesSection>
      <Left>
        <img src={darkspectrum2} alt="dark-spectrum-tattoo" />
      </Left>
      <Right>
        <div className="group text-format">
          <h3>The solution</h3>
          <p>
            The solution was a custom-designed website that perfectly
            encapsulated the Dark Spectrum Tattoo Collective's ethos.
          </p>
          <p>
            I aimed to avoid a busy, cluttered design that could potentially
            overwhelm visitors and detract from the artists' work.
          </p>
          <p>
            Harmony is the style provided by the webpage: dark background,
            branding element as a central element of the page, strong and clear
            headline establishing positive emotional message, visual elements
            enabling immediate perception of the theme and clearly visible call
            to action so that users could get the basic information immediately.
          </p>
          <p>
            A simple and intuitive navigation system was implemented, enabling
            users to quickly find the information they needed and book
            appointments with ease.
          </p>
          <p>
            Incorporating smooth animation in my design not only adds a dynamic
            element but also reinforces the sense of consistent interaction.
          </p>
        </div>
      </Right>
    </StylesSection>
  );
};

export default LeftImage;
