import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 60px auto;
  text-align: left;
  padding: 0 20px;
`;

const SimpleText = () => {
  return (
    <Text className="text-format">
      <div className="group text-format">
        <h3>Used tools</h3>
        <p>
          Figma for UI design, Miro for brainstorming and planning, Loom for
          communication and collaboration.
        </p>
      </div>
    </Text>
  );
};

export default SimpleText;
