import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 0 auto 60px auto;
  text-align: left;
  padding: 0 20px;
  .topMargin {
    margin-top: 60px;
  }
  ul {
    @media (max-width: 1150px) {
      margin-top: -40px;
    }
  }
`;

const SimpleTextBottom = () => {
  return (
    <Text className="text-format">
      <div className="group text-format">
        <h3>The result</h3>
        <p>
          The result was a user-friendly fitness app that empowered first-time
          runners to gradually build their endurance and work towards running
          5km or 30 minutes in just 9 weeks.
        </p>
        <p>
          The app was well-received by users, who appreciated its simplicity,
          personalised coaching, and the ability to track their progress in an
          interactive way.
        </p>
        <p>
          The app also fostered a sense of community by enabling users to run
          together virtually and share their goals and challenges.
        </p>
      </div>
      <div className="group text-format">
        <h3>Used tools</h3>
        <p>
          Figma for UI design, Miro for brainstorming and planning, Notion for
          documentation.
        </p>
      </div>
    </Text>
  );
};

export default SimpleTextBottom;
