import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();

  const [isLoaded, setIsLoaded] = useState(false);

  window.addEventListener("load", (event) => {
    setIsLoaded(true);
  });

  useEffect(() => {
    if (hash === "#contact-now") {
      const bottom = document.body.offsetHeight;
      isLoaded &&
        document.documentElement.scrollTo({
          top: bottom,
          left: 0,
          behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    } else {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }
  }, [pathname, hash, isLoaded]);

  return null;
}
