import React from "react";
import styled from "styled-components";

import darkspectrum1 from "../../../images/portfolio/darkspectrum1.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 0px auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Left = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
  }
  img {
    width: 100%;
    max-width: 750px;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const RightImage = () => {
  return (
    <StylesSection>
      <Left>
        <div className="group text-format">
          <h3>Background</h3>
          <p>
            The Dark Spectrum Tattoo Collective is a unique tattoo shop with a
            mission to transform clients' ideas into timeless pieces of art.
          </p>
          <p>
            My role was to design a presentation website that would effectively
            communicate their mission, showcase their work, and provide an
            intuitive user experience.
          </p>
        </div>
        <div className="group text-format">
          <h3>The challenge</h3>
          <p>
            The challenge was to create a digital platform that would mirror the
            unique style and ethos of the Dark Spectrum Tattoo Collective.
          </p>
          <p>
            Understanding the unique needs of the Dark Spectrum Tattoo
            Collective and its potential clients was the first step in my
            approach.
          </p>
          <p>
            Recognizing that users would be accessing the site from a variety of
            devices, it was crucial to ensure that the website would perform
            optimally on any screen size.
          </p>
          <p>
            The website needed to be user-friendly, allowing potential clients
            to explore the artists' portfolios, understand their styles, and
            book appointments with minimal difficulty.
          </p>
        </div>
      </Left>
      <Right>
        <img src={darkspectrum1} alt="dark-spectrum" />
      </Right>
    </StylesSection>
  );
};

export default RightImage;
