import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 0 auto 60px auto;
  text-align: left;
  padding: 0 20px;
  .topMargin {
    margin-top: 60px;
  }
  ul {
    @media (max-width: 1150px) {
      margin-top: -40px;
    }
  }
`;

const SimpleTextBottom = () => {
  return (
    <Text className="text-format">
      <div className="group text-format">
        <h3>The result</h3>
        <p>
          The redesigned dashboard provides a more user-friendly experience,
          making it easier for users to manage potential delivery and fulfilment
          issues. The clear, intuitive design helps users quickly understand the
          status of each order and what actions need to be taken, improving
          efficiency and productivity.
        </p>
      </div>
      <div className="group text-format">
        <h3>Used tools</h3>
        <p>
          Figma for UI design, Miro for brainstorming and planning, Loom for
          communication and collaboration, ClickUP for project management,
          Notion for documentation.
        </p>
      </div>
      <div className="group text-format">
        <h3>Personal conclusion</h3>
        <p>
          This project demonstrates my ability to take complex data and present
          it in a clear, easy-to-understand format, and to design intuitive,
          user-friendly dashboards for SaaS applications.
        </p>
      </div>
    </Text>
  );
};

export default SimpleTextBottom;
