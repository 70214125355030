import React from "react";
import styled from "styled-components";

const StyledDetails = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    text-decoration: none;
    color: var(--dark-green);
  }
  p {
    margin-bottom: 8px;
  }
`;

const Details = () => {
  return (
    <StyledDetails className="text-format">
      <h3>Project details</h3>
      <p>Company: Shopceed BV</p>
      <p>Location: Amsterdam, Netherlands</p>
      <p>
        Website:{" "}
        <a
          href="https://shopceed.com"
          target="_blank"
          rel="noindex nofollow noopener noreferrer"
        >
          www.shopceed.com
        </a>
      </p>
      <p>Contract type: Freelance (Remote)</p>
    </StyledDetails>
  );
};

export default Details;
