import React from "react";
import FooterSection from "../../../sections/Footer";
import Top from "../../../sections/Top";
import PageTitle from "../../../components/PageTitle";
import SubHeading from "../../../sections/SubHeading";
import RightImage from "./RightImage";
import SimpleText from "./SimpleText";
import LeftImage from "./LeftImage";
import SimpleTextBottom from "./SimpleTextBottom";

import ProjectsPagination from "../../../sections/ProjectsPagination";
import Details from "./Details";

const BrighteningHero = () => {
  return (
    <>
      <PageTitle title="Brightening Hero | @designwithella.com" />
      <Top
        title="Brightening Hero E&#8209;Commerce"
        titleBgOne="#4D8219"
        titleBgTwo="#5B9346"
        titleBgThree="#6db552"
      />
      <SubHeading text="Bespoke E&#8209;Commerce responsive website" />
      <RightImage />
      <SimpleText />
      <LeftImage />
      <SimpleTextBottom />
      <Details />
      <ProjectsPagination
        prevTitle={"Bovoba SaaS Application"}
        prevUrl={"/project-bovoba-dashboard"}
        prevcolor={"#3C83F6"}
        nextTitle={"Dark Spectrum Tattoo Website"}
        nextUrl={"/project-dark-spectrum-tattoo"}
        nextcolor={"#D63B6F"}
      />
      <FooterSection />
    </>
  );
};

export default BrighteningHero;
