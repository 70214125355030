import React from "react";
import styled from "styled-components";

import bovoba2 from "../../../images/portfolio/bovoba2.svg";

const StylesSection = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 80px auto 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const Right = styled.div`
  width: 40%;
  text-align: left;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 55%;
  @media (max-width: 1150px) {
    width: 100%;
    img {
      margin-bottom: 60px;
    }
  }
  img {
    width: 100%;
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
    max-width: 750px;
  }
`;

const LeftImage = () => {
  return (
    <StylesSection>
      <Left>
        <img src={bovoba2} alt="bovoba" />
      </Left>
      <Right>
        <div className="group text-format">
          <h3>The solution</h3>
          <p>
            I redesigned the dashboard to include a clear summary overview at
            the top of the screen, providing a snapshot of any issues from the
            last seven days. I also included a day summary field for each day,
            highlighting any fulfilment issues and required actions.
          </p>
          <p>
            I included a powerful filtering system that allows users to narrow
            down specific orders based on various criteria. I introduced a
            cascading dropdown feature that enables users to select from
            different filter options and make multiple selections.
          </p>
          <p>
            This empowers users to filter for specific conditions, such as
            delayed orders received in the last two weeks with DHL as the
            carrier.
          </p>
          <p>
            The filter system provides a seamless and efficient way for users to
            locate and manage orders based on their unique needs.
          </p>
          <p>
            In addition to the filtering system, I implemented a powerful search
            input that allows users to quickly search for specific orders using
            keywords, order numbers, or customer names. The search functionality
            saves time and enhances user productivity by providing instant
            results that match the search query.
          </p>
          <p>
            For each order, I designed a system for displaying the order status
            explainer text, which provides a clear explanation of the order's
            current status and any potential issues. I also included a system
            for tracking follow-up actions, allowing users to easily see what
            actions have been taken and what still needs to be done.
          </p>
        </div>
      </Right>
    </StylesSection>
  );
};

export default LeftImage;
