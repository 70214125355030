import React from "react";
import styled from "styled-components";

const Text = styled.section`
  width: 100%;
  max-width: var(--content-size);
  margin: 60px auto;
  text-align: left;
  padding: 0 20px;
  .challenge-left {
    @media (min-width: 1400px) {
      display: none;
    }
  }
`;

const SimpleText = () => {
  return (
    <Text className="text-format">
      <div className="group text-format challenge-left">
        <h3>The challenge</h3>
        <p>
          Ramaroo UGC Video Player app aims to address the challenges online
          store users face when discovering and organising video content across
          multiple platforms.
        </p>
        <p>
          Users often struggle to find relevant videos that match their
          interests, navigate through vast content libraries efficiently, and
          manage their personal video collections effectively. This leads to
          frustration, wasted time, and missed opportunities to increase sales.
        </p>
      </div>
    </Text>
  );
};

export default SimpleText;
